import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ReactMarkdown from "react-markdown"
import { reactMarkDown } from "../styles/markdown.module.css"

const BlogPost = ({ data }) => {
  console.log(data.strapiBlog)
  const post = data.strapiBlog
  return (
    <Layout>
      <Seo title={post.Title} description={post.Description} />
      <div className="mt-36 flex px-4 flex-col justify-center max-w-2xl mx-auto ">
        <span className="mt-12 block">{post.Date}</span>
        <h1 className="font-black text-4xl leading-normal md:leading-relaxed  md:text-6xl ">
          {post.Title}
        </h1>
        <hr className="w-1/6 my-8 relative" />
      </div>
      <div className="max-w-2xl mt-8 mb-24 mx-auto text-justify font-sans">
        <ReactMarkdown className={reactMarkDown}>
          {post.Content.data.Content}
        </ReactMarkdown>
      </div>
    </Layout>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostBySlug($Slug: String!) {
    strapiBlog(Slug: { eq: $Slug }) {
      Date(formatString: "DD MMMM, YYYY", locale: "pl")
      Slug
      Title
      Description
      Content {
        data {
          Content
        }
      }
    }
  }
`
